import * as XLSX from 'xlsx';

export const exportToXLSX = (historicOrderData, clientFilter, clinicFilter, selectedMonth) => {
    const filteredAndSortedData = historicOrderData
        .filter((item) =>
            !item.orderID.includes("k") &&
            item.patient.toLowerCase().includes(clientFilter.toLowerCase()) &&
            item.clinicName.toLowerCase().includes(clinicFilter.toLowerCase()) &&
            (selectedMonth === "VISI" || (/\/(\d{2})\//.test(item.orderReturnDate) && RegExp.$1 === selectedMonth))
        );

    const xlsxData = prepareXLSXData(filteredAndSortedData);
    const sheetData = xlsxData.map(row => Object.values(row));
    sheetData.unshift(Object.keys(xlsxData[0]));

    const ws = XLSX.utils.aoa_to_sheet(sheetData);

    // // Add logo to the top left corner
    // const logoPath = 'logo.png'; // Update with the actual path to your logo
    // const logoHeight = 100;
    // XLSX.utils.sheet_addImage({
    //     worksheet: ws,
    //     image: {
    //         path: logoPath,
    //         type: 'image/png',
    //         position: {
    //             type: 'absoluteAnchor',
    //             from: {
    //                 col: 0,
    //                 colOff: '0px',
    //                 row: 0,
    //                 rowOff: '0px',
    //             },
    //         },
    //         dimension: {
    //             height: logoHeight,
    //         },
    //     },
    // });

    const wb = XLSX.utils.book_new();

    const wscols = [
        { wch: 10 },
        { wch: 13 },
        { wch: 13 },
        { wch: 10 },
        { wch: 10 },
        { wch: 16 },
        { wch: 9 },
        { wch: 13 },
        { wch: 6 },
        { wch: 10 },
    ];
    ws['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    const xlsxFileName = generateXLSXFileName(clinicFilter, selectedMonth);
    XLSX.writeFile(wb, xlsxFileName);
};

const prepareXLSXData = (data) => {
    return data.map((item) => {
        const implantsArray = item.implants
            ? item.implants.split(';').filter(implant => implant.split(':')[1].trim() !== '-')
            : [];
        const uniqueImplants = Array.from(new Set(implantsArray.map(implant => implant.split(':')[1])));

        const teethArray = item.teeth ? item.teeth.split(/[-,]/).map(Number).filter(Boolean) : [];
        const uniqueTeethCount = teethArray.length;

        const dantysValue = uniqueImplants.length > 0
            ? `${item.teeth} + ${implantsArray.length} impl.`
            : item.teeth;

        return {
            'Klinika': item.clinicName,
            'Gydytojas': item.doctor,
            'Pacientas': item.patient,
            'Užsakymo data': item.orderDate,
            'Užs. atidavimo data': item.orderReturnDate,
            'Restauracija': item.restoration,
            'Dantu skaičius': uniqueTeethCount,
            'Implantai': uniqueImplants.join(', ') || '-',
            'Impl. bazių skaičius': implantsArray.length || '-',
            'Dantys': dantysValue,
            'Dantų kaina': "",
            'Impl. bazių kaina': "",
        };
    });
};

const generateXLSXFileName = (clientFilter, selectedMonth) => {
    const monthNames = [
        "Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis",
        "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"
    ];

    const monthName = selectedMonth !== "VISI" ? monthNames[parseInt(selectedMonth) - 1] : "Visi_menesiai";

    return `${clientFilter}_${monthName}.xlsx`;
};

// import { CSVLink } from 'react-csv';

// export const exportToCSV = (historicOrderData, clientFilter, clinicFilter, selectedMonth) => {
//     //console.log(historicOrderData, clientFilter, clinicFilter, selectedMonth)
//     const filteredAndSortedData = historicOrderData
//         // .reduce((uniqueOrders, item) => {
//         //   // Your existing logic to filter and sort data
//         // }, [])
//         .filter((item) =>
//             !item.orderID.includes("k") &&
//             item.patient.toLowerCase().includes(clientFilter.toLowerCase()) &&
//             item.clinicName.toLowerCase().includes(clinicFilter.toLowerCase()) &&
//             (selectedMonth === "VISI" || (/\/(\d{2})\//.test(item.orderReturnDate) && RegExp.$1 === selectedMonth))
//         );

//     const csvData = prepareCSVData(filteredAndSortedData);
//     const csvFileName = generateCSVFileName(clinicFilter, selectedMonth);

//     return (
//         <CSVLink data={csvData} filename={csvFileName}>
//             Export CSV
//         </CSVLink>
//     );
// };

// const prepareCSVData = (data) => {
//     return data.map((item) => {
//         const implantsArray = item.implants
//         ? item.implants.split(';').filter(implant => implant.split(':')[1].trim() !== '-')
//         : [];
//         const uniqueImplants = Array.from(new Set(implantsArray.map(implant => implant.split(':')[1])));

//         const teethArray = item.teeth ? item.teeth.split(/[-,]/).map(Number).filter(Boolean) : [];
//         const uniqueTeethCount = teethArray.length;

//         const dantysValue = uniqueImplants.length > 0
//         ? `${item.teeth} + ${implantsArray.length} impl.`
//         : item.teeth;

//         return {
//             'Klinika': item.clinicName,
//             'Gydytojas': item.doctor,
//             'Pacientas': item.patient,
//             'Užsakymo data': item.orderDate,
//             'Užsakymo atidavimo data': item.orderReturnDate,
//             'Restauracija': item.restoration,
//             'Dantu skaičius': uniqueTeethCount,
//             'Implantai': uniqueImplants.join(', ') || '-',
//             'Implantu bazių skaičius': implantsArray.length || '-',
//             'Dantys': dantysValue,
//             'Dantų kaina': "",
//             'Implantų bazių kaina': "",
//         };
//     });
// };

// const generateCSVFileName = (clientFilter, selectedMonth) => {
//     const monthNames = [
//         "Sausis", "Vasaris", "Kovas", "Balandis", "Gegužė", "Birželis",
//         "Liepa", "Rugpjūtis", "Rugsėjis", "Spalis", "Lapkritis", "Gruodis"
//     ];

//     const monthName = selectedMonth !== "VISI" ? monthNames[parseInt(selectedMonth) - 1] : "Visi_menesiai";

//     return `${clientFilter}_${monthName}.csv`;
// };