import React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { StyledTypography, defaultStyling } from './../Styling';

const ImplantsList = ({ checkedTeeth, implant, implantName, implantOnChange }) => {

    return (
        checkedTeeth.thisTeeth.length === 0 ? null :
            checkedTeeth.thisTeeth.map((number) => (
                number.includes('-') ?
                    number.toString().split('-').map((singleTeeth, index) => (
                        <div key={singleTeeth + index}>
                            <Box sx={{ m: 2, display: 'flex', alignItems: 'center', ...defaultStyling }}>
                                <StyledTypography><b>{singleTeeth}:</b></StyledTypography>
                                <Autocomplete sx={{ width: 300 }}
                                    options={implant}
                                    id="implant"
                                    value={implantName == null ? '' : implantName[singleTeeth]}
                                    onChange={(i, newValue) => { implantOnChange(singleTeeth, newValue) }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Implantai" />
                                    )}
                                />
                            </Box>
                        </div>
                    ))
                    :
                    <div key={number}>
                        <Box sx={{ m: 2, display: 'flex', alignItems: 'center', ...defaultStyling }}>
                            <StyledTypography><b>{number}:</b></StyledTypography>
                            <Autocomplete sx={{ width: 300 }}
                                options={implant}
                                id="implant"
                                value={implantName == null ? '' : implantName[number]}
                                onChange={(i, newValue) => { implantOnChange(number, newValue) }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Implantai" />
                                )}
                            />
                        </Box>
                    </div>
            ))
    )
}

export default ImplantsList;