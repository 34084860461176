/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      name
      clinicID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      name
      clinicID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      name
      clinicID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRestorations = /* GraphQL */ `
  mutation CreateRestorations(
    $input: CreateRestorationsInput!
    $condition: ModelRestorationsConditionInput
  ) {
    createRestorations(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRestorations = /* GraphQL */ `
  mutation UpdateRestorations(
    $input: UpdateRestorationsInput!
    $condition: ModelRestorationsConditionInput
  ) {
    updateRestorations(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRestorations = /* GraphQL */ `
  mutation DeleteRestorations(
    $input: DeleteRestorationsInput!
    $condition: ModelRestorationsConditionInput
  ) {
    deleteRestorations(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAllOrders = /* GraphQL */ `
  mutation CreateAllOrders(
    $input: CreateAllOrdersInput!
    $condition: ModelAllOrdersConditionInput
  ) {
    createAllOrders(input: $input, condition: $condition) {
      id
      orderID
      clinic
      clinicName
      doctor
      patient
      patientDOB
      patientGender
      patientOtherInfo
      orderDate
      orderReturnDate
      teeth
      teethName
      restoration
      implants
      color
      additionalInfo
      orderState
      expedited
      fileList
      fileListSTL
      orderCorrectionDate
      orderCorrectionReturnDate
      orderCorrectionInformation
      fileListOfCorrection
      fileListSTLOfCorrection
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAllOrders = /* GraphQL */ `
  mutation UpdateAllOrders(
    $input: UpdateAllOrdersInput!
    $condition: ModelAllOrdersConditionInput
  ) {
    updateAllOrders(input: $input, condition: $condition) {
      id
      orderID
      clinic
      clinicName
      doctor
      patient
      patientDOB
      patientGender
      patientOtherInfo
      orderDate
      orderReturnDate
      teeth
      teethName
      restoration
      implants
      color
      additionalInfo
      orderState
      expedited
      fileList
      fileListSTL
      orderCorrectionDate
      orderCorrectionReturnDate
      orderCorrectionInformation
      fileListOfCorrection
      fileListSTLOfCorrection
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAllOrders = /* GraphQL */ `
  mutation DeleteAllOrders(
    $input: DeleteAllOrdersInput!
    $condition: ModelAllOrdersConditionInput
  ) {
    deleteAllOrders(input: $input, condition: $condition) {
      id
      orderID
      clinic
      clinicName
      doctor
      patient
      patientDOB
      patientGender
      patientOtherInfo
      orderDate
      orderReturnDate
      teeth
      teethName
      restoration
      implants
      color
      additionalInfo
      orderState
      expedited
      fileList
      fileListSTL
      orderCorrectionDate
      orderCorrectionReturnDate
      orderCorrectionInformation
      fileListOfCorrection
      fileListSTLOfCorrection
      paymentStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrderNumber = /* GraphQL */ `
  mutation CreateOrderNumber(
    $input: CreateOrderNumberInput!
    $condition: ModelOrderNumberConditionInput
  ) {
    createOrderNumber(input: $input, condition: $condition) {
      id
      orderNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOrderNumber = /* GraphQL */ `
  mutation UpdateOrderNumber(
    $input: UpdateOrderNumberInput!
    $condition: ModelOrderNumberConditionInput
  ) {
    updateOrderNumber(input: $input, condition: $condition) {
      id
      orderNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOrderNumber = /* GraphQL */ `
  mutation DeleteOrderNumber(
    $input: DeleteOrderNumberInput!
    $condition: ModelOrderNumberConditionInput
  ) {
    deleteOrderNumber(input: $input, condition: $condition) {
      id
      orderNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
