import { useState, useCallback } from 'react';
import { API } from 'aws-amplify';
import * as queries from './graphql/queries';

const useRestorations = () => {
  const [restoration, setCurrentRestorationValue] = useState(null);
  const [restorationEnablingImplants, setCurrentRestorationEnablingImplantsValue] = useState(null);
  const [implant, setCurrentImplantsValue] = useState(null);
  const [currentRestorationValueVersion, setCurrentRestorationValueVersion] = useState('');
  const [currentRestorationEnablingImplantsValueVersion, setCurrentRestorationEnablingImplantsValueVersion] = useState('');
  const [currentImplantsValueVersion, setCurrentImplantsValueVersion] = useState('');
  const [error, setError] = useState(null);

  // Define a method to fetch restorations
  const getRestorations = useCallback(async () => {
    try {
      const restorationsData = await API.graphql({
        query: queries.listRestorations,
      });

      const restorationsList = restorationsData.data.listRestorations.items;

      restorationsList.forEach((el, i) => {
        if (el?.id === "1") {
          let arr = el.name.split(",")
          setCurrentRestorationValue(arr)
          setCurrentRestorationValueVersion(el._version)
        }
        if (el?.id === "2") {
          let arr = el.name.split(",")
          setCurrentRestorationEnablingImplantsValue(arr)
          setCurrentRestorationEnablingImplantsValueVersion(el._version)
        }
        if (el?.id === "3") {
          let arr = el.name.split(",")
          setCurrentImplantsValue(arr)
          setCurrentImplantsValueVersion(el._version)
        }
      });

    } catch (error) {
      setError(error.message || 'Failed to find restorations');
    }
  }, []);

  return { restoration, restorationEnablingImplants, implant, currentRestorationValueVersion, currentRestorationEnablingImplantsValueVersion, currentImplantsValueVersion, error, getRestorations };
};

export default useRestorations;