import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";

export const StyledTypography = styled(Typography)(({ theme }) => ({
    borderRadius: 4,
    border: '0px solid',
    borderColor: theme.palette.primary.main,
    maxHeight: '1.5rem',
    padding: 4,
    margin: 8
  }));
  
export const defaultStyling = {
    border: '0px solid gray'
  }