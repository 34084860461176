import { useState, useCallback } from 'react';
import { API } from 'aws-amplify';
import * as queries from './graphql/queries';

const useHistoricOrders = (userEmail, isAdmin) => {
  const [historicOrders, setHistoricOrders] = useState(null);
  const [error, setError] = useState(null);

  // Define a method to fetch historic orders
  const getHistoricOrders = useCallback(async () => {

    let clinicFilter = isAdmin.includes(userEmail) ? { clinic: { contains: "@" } } : { clinic: { contains: userEmail } };
    let allHistoricOrders = [];
    let nextToken = null;

    try {
      do {
        const historicOrdersResponse = await API.graphql({
          query: queries.listAllOrders,
          variables: {
            filter: clinicFilter,
            limit: 100, // Set the desired batch size
            nextToken: nextToken // Use the nextToken to paginate
          }
        });

        const historicOrdersData = historicOrdersResponse.data;

        if (!historicOrdersData || !historicOrdersData.listAllOrders) {
          break;
        }

        const items = historicOrdersData.listAllOrders.items.filter(item => item._deleted !== true);

        allHistoricOrders.push(...items);
        nextToken = historicOrdersData.listAllOrders.nextToken;

      } while (nextToken);

      // Function to parse "DD/MM/YYYY" date format
      function parseDate(dateString) {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
      }

      // Filter orders with "Gaminama" or "Uzsakyta" state
      const filteredOrders = allHistoricOrders.filter(
        (order) => order.orderState === 'Gaminama' || order.orderState === 'Užsakyta'
      );

      // Filter orders without "Gaminama" or "Uzsakyta" state
      const remainingOrders = allHistoricOrders.filter(
        (order) => order.orderState !== 'Gaminama' && order.orderState !== 'Užsakyta'
      );

      // Sort both groups by 'orderReturnDate' using the custom date parsing function
      filteredOrders.sort((a, b) => parseDate(a.orderReturnDate) - parseDate(b.orderReturnDate));
      remainingOrders.sort((a, b) => parseDate(a.orderReturnDate) - parseDate(b.orderReturnDate));

      // Combine the sorted filtered and remaining orders
      const sortedHistoricOrders = [...filteredOrders, ...remainingOrders];

      setHistoricOrders(sortedHistoricOrders);

    } catch (fetchError) {
      setError(fetchError.message || 'Failed to get orders');
    }
  }, [userEmail, isAdmin]);

  // Function to update a specific order's data in the local state
  const updateLocalOrderState = (updatedOrder) => {
    setHistoricOrders((currentOrders) =>
      currentOrders.map((order) =>
        order.id === updatedOrder.id ? { ...order, ...updatedOrder } : order
      )
    );
  };

  return { historicOrders, updateLocalOrderState, error, getHistoricOrders };
};

export default useHistoricOrders;