import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const MenuComponent = ({ onEditClick, handleExportToXLSX, handlePendingPayment, handlePaidPayment, handleClearPayment }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          sx={{ ml: 2 }}
        >
          VEIKSMAI
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { handleClose(); handleExportToXLSX(); }}>EXPORT XLSX</MenuItem>
          <MenuItem onClick={() => { handleClose(); handlePendingPayment(); }}>LAUKIAMA APM.</MenuItem>
          <MenuItem onClick={() => { handleClose(); handlePaidPayment(); }}>APMOKĖTA</MenuItem>
          <MenuItem onClick={() => { handleClose(); handleClearPayment(); }}>NEPARUOŠTA</MenuItem>
          <MenuItem onClick={() => { handleClose(); onEditClick(); }}>REDAGUOTI</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default MenuComponent;
