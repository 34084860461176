import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import logo from './logo.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import "./ComponentToPrint.css";
import TextField from '@mui/material/TextField';
import { BoltRounded } from '@mui/icons-material';

const styles = {
  pelements: { fontSize: 13, margin: "5px" },
  tableHeaders: { fontSize: 13, fontWeight: 'bold', paddingLeft: 2, paddingRight: 2, paddingTop: 3, paddingBottom: 3  },
  tableContent: { fontSize: 13, paddingLeft: 2, paddingRight: 2, paddingTop: 3, paddingBottom: 0 },
  }

const ComponentToPrint = forwardRef((props, ref) => {
  //console.log("orderReturnDate", props.orderReturnDate)

  let patientGenderLT = ''

  if (props.patientGender === "male") {
    patientGenderLT = "Vyras"
  } else {
    patientGenderLT = "Moteris"
  }

  let expedited

  //console.log(props.expedited.expedited)

  if (props.expedited.expedited === true) {
    expedited = "SKUBUS"
  }

  return (

    <div ref={ref}>
      <Box sx={{ flexGrow: 1, paddingLeft: 1, paddingRight: 1, paddingTop: 1 }}>
        <Grid container spacing={2}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs="auto" paddingTop={3} paddingLeft={4} paddingRight={10}>
              <img src={logo} alt="Plati Šypsena" height="40px" />
            </Grid>
            <Grid item xs="auto" paddingTop={2}>

              <p>Užsakymo nr.: <b>{props.orderID}</b></p>

            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
          >
            <Grid item xs={7} paddingLeft={3}>
              <p style={styles.pelements}>Užsakovas: <b>{props.clinicName}</b></p>
              <p style={styles.pelements}>Darbo atidavimo terminas: <b>{props.orderReturnDate}</b> {expedited ? <span><b>SKUBUS!</b></span> : null}</p>
            </Grid>

            <Grid item xs={5}>
              <p style={styles.pelements}>Gydytojas: <b>{props.doctor}</b></p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs="auto" paddingLeft={3} paddingTop={1} paddingBottom={1}>
              <p style={styles.pelements}>Paciento vardas, pavardė: <b>{props.patientName}</b></p>
              <p style={styles.pelements}>Lytis: <b>{patientGenderLT}</b></p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} paddingLeft={3} paddingBottom={1}>
              <p style={styles.pelements}>Kita svarbi informacija apie pacientą: <b>{props.patientAdditionalInfo}</b></p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} paddingLeft={3} paddingRight={1}>
              <TableContainer component={Paper}>
                <Table sx={{}} size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow key="random"
                      sx={{
                        "& th": {
                          color: "rgba(96, 96, 96)",
                          backgroundColor: "whitesmoke",
                        }
                      }}
                    >
                      <TableCell width="10%" style={styles.tableHeaders}>Dantys</TableCell>
                      <TableCell width="25%" style={styles.tableHeaders} align="left">Restauracija</TableCell>
                      <TableCell width="25%" style={styles.tableHeaders} align="left">Implantai </TableCell>
                      <TableCell width="7%" style={styles.tableHeaders} align="left">Spalva</TableCell>
                      <TableCell width="33%" style={styles.tableHeaders} align="left">Papildoma informacija</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.orderData?.map((items, i) => (
                      <TableRow
                        key={i + items}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {/* <TableCell component="th" scope="row">{items.selectedTeeth}</TableCell> */}
                        <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedTeeth?.map((line, index) =>
                          index == 0 ? <span key={index + line}>{line}</span> : <span key={index + line}> / {line}</span>
                        )}
                        </TableCell>
                        <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedRestoration?.map((line, index) => <p key={index + line}>{line}</p>)}</TableCell>
                        <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedImplant?.map((line, index) =>
                          <div key={index + line}>
                            {line.map((teethnumber, teethnumberindex) =>
                              teethnumberindex == 0 ? <span key={teethnumberindex + teethnumber}>{teethnumber}</span> : <span key={teethnumberindex + teethnumber}>: {teethnumber}</span>

                            )}
                          </div>
                        )}</TableCell>
                        <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedColor}</TableCell>
                        <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedAdditionalInfo}</TableCell>

                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>


          {props.orderDataOfCorrection?.[0] ?
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} paddingLeft={3} paddingRight={1} paddingTop={1}>
                <p style={styles.pelements}><b>Užsakymo korekcijos</b></p>
                <TableContainer component={Paper}>
                  <Table sx={{}} size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow key="random"
                        sx={{
                          "& th": {
                            color: "rgba(96, 96, 96)",
                            backgroundColor: "whitesmoke"
                          }
                        }}
                      >
                        <TableCell width="20%" style={styles.tableHeaders} align="left">Korekcijos nr.</TableCell>
                        <TableCell width="20%" style={styles.tableHeaders} align="left">Korekcijos data</TableCell>
                        <TableCell width="20%" style={styles.tableHeaders} align="left">Atidavimo data</TableCell>
                        <TableCell width="40%" style={styles.tableHeaders} align="left">Korekcijos informacija</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.orderDataOfCorrection?.map((items, i) => (
                        <TableRow
                          key={i + items}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedOrderCorrectionID}</TableCell>
                          <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedOrderCorrectionDate}</TableCell>
                          <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedOrderCorrectionReturnDate}</TableCell>
                          <TableCell style={styles.tableContent} component="th" scope="row">{items.selectedOrderCorrectionInformation}</TableCell>

                        </TableRow>
                      ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            : null
          }

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="right"
          >
            <Grid item xs={6} paddingLeft={3} paddingTop={2}>
              <p style={styles.pelements}>UŽSAKOVO DANTŲ PROTEZUS GAMINAU:</p>
            </Grid>
            <Grid item xs={5} paddingTop={2}>
              <TextField
                fullWidth
                id="standard-helperText"
                helperText="Dantų techniko v., pavardė, parašas"
                variant="standard"
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="right"
          >
            <Grid item xs={6} paddingLeft={3}>
              <p style={styles.pelements}>SU PROTEZO SPALVA, <br /> KONSTRUKCIJA SUSIPAŽINAU IR SUTINKU:</p>
            </Grid>
            <Grid item xs={5} paddingTop={2}>
              <TextField
                fullWidth
                id="standard-helperText"
                helperText="Gydytojo odontologo v., pavardė, parašas"
                variant="standard"
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="right"
          >
            <Grid item xs={12} paddingLeft={3}>
              <p style={{ textAlign: "left", fontSize: 13 }}>DEZINFEKACIJA <Checkbox size="medium" /></p>
            </Grid>
          </Grid> */}

        </Grid>
      </Box>
    </div>

  )
});

export default ComponentToPrint;