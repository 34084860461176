import { useState } from 'react';
import { API } from 'aws-amplify';
import * as queries from './graphql/queries';

const useDoctors = (userEmail, isAdmin) => {
  const [doctors, setDoctors] = useState([]);
  const [error, setError] = useState(null);

  const getDoctors = async () => {
    let clinicFilter = isAdmin.includes(userEmail)
      ? { clinicID: { contains: "@" } }
      : { clinicID: { contains: userEmail } };

    try {
      const doctorsData = await API.graphql({
        query: queries.listDoctors,
        variables: { filter: clinicFilter }
      });

      setDoctors(doctorsData.data.listDoctors.items);
    } catch (error) {
      setError("Failed to find doctors");
    }
  };

  return { doctors, error, getDoctors };
};

export default useDoctors;